import React from "react";

function Footer() {
  return (
    <footer className={"footer"}>
      Copyright © 2022 | SICERDIK - Dinas Pendidikan Kota Tanjungpinang
    </footer>
  );
}

export default Footer;
