const surat_pindah = require("../../assets/surat_pindah_sekolah.pdf");
const surat_ortu = require("../../assets/surat_permohonan_ortu.pdf");
const data = [
  {
    id: 1,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN3 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN4 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "BELUM",
    status_ttd: "BELUM",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 2,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN5 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN2 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "BELUM",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: "",
  },

  {
    id: 3,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SDN5 Tanjungpinang Timur",
    tujuan_sekolah: "SDN2 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "SUDAH",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: "",
  },
  {
    id: 4,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN5 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN4 Batam",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "SUDAH",
    status_kirim: "SUDAH",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 5,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN5 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN2 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "BELUM",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 6,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SDN5 Tanjungpinang Timur",
    tujuan_sekolah: "SDN2 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "SUDAH",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 7,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN5 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN4 Batam",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "SUDAH",
    status_kirim: "SUDAH",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: "",
  },
  {
    id: 8,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN3 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN4 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "BELUM",
    status_ttd: "BELUM",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 9,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN5 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN2 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "BELUM",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },

  {
    id: 10,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SDN5 Tanjungpinang Timur",
    tujuan_sekolah: "SDN2 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "SUDAH",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 11,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN5 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN4 Batam",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "SUDAH",
    status_kirim: "SUDAH",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 12,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN5 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN2 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "BELUM",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 13,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SDN5 Tanjungpinang Timur",
    tujuan_sekolah: "SDN2 Tanjungpinang Bukit Bestari",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "SUDAH",
    status_kirim: "BELUM",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
  {
    id: 14,
    tgl_naskah: "24 Jan 2022",
    hal: "Lap Pindah Sekolah",
    asal_sekolah: "SMPN5 Tanjungpinang Timur",
    tujuan_sekolah: "SMPN4 Batam",
    yang_menandatangani: "Belum Ditentukan",
    status_verifikasi: "SUDAH",
    status_ttd: "SUDAH",
    status_kirim: "SUDAH",
    surat_ortu: surat_ortu,
    surat_pindah: surat_pindah,
    surat_plh: surat_pindah,
  },
];

module.exports = data;
