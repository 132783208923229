function dapatkanBulan(angka) {
    if (angka == 1) {
      return "Januari";
    } else if (angka == 2) {
      return "Februari";
    } else if (angka == 3) {
      return "Maret";
    } else if (angka == 4) {
      return "April";
    } else if (angka == 5) {
      return "Mei";
    } else if (angka == 6) {
      return "Juni";
    } else if (angka == 7) {
      return "Juli";
    } else if (angka == 8) {
      return "Agustus";
    } else if (angka == 9) {
      return "September";
    } else if (angka == 10) {
      return "Oktober";
    } else if (angka == 11) {
      return "November";
    } else if (angka == 12) {
      return "Desember";
    }
  }

export default dapatkanBulan